<template>
    <div v-bind:class="[ bgcolor, border ]" class="h-navbar flex px-3">
        <div v-show="!noback" class="h-menu w-menu papsi-back-icon">
            <img v-on:click="click" v-bind:src="icon" class="block" />
        </div>
        <div v-if="isNormal()" class="flex-grow pl-1 text-lg flex justify-content items-center h-navbar">
            <span v-text="title"></span>
        </div>
        <div v-show="isNormal()">
            <div v-show="!login || !menu" class="papsi-snowman-icon">
                <img src="@/assets/icons/papsi_snowman.svg" class="block" />
            </div>
            <div v-show="login && menu" class="w-menu h-menu papsi-back-icon">
                <img v-on:click="openSidebar" src="@/assets/icons/papsi_menu_dark.svg" class="block" />
            </div>
        </div>
        <div v-show="!isNormal()" class="flex-grow"></div>
        <div v-on:click="goProfile" v-show="!isNormal() && login" class="h-menu">
            <div class="flex">
                <div class="text-white font-bold truncate text-lg pr-2 h-navbar flex justify-content items-center">
                    <span v-text="username"></span>
                </div>
                <div class="w-menu h-menu bg-white rounded-full papsi-avatar-mt">
                    <img src="@/assets/icons/papsi_avatar.svg" class="block" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-navbar',
    props: {
        type: {
            type: String,
            default: 'normal',
        },
        title: {
            type: String,
            required: true,
        },
        noback: {
            type: Boolean,
            default: false,
        },
        menu: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            icon: '',
            login: false,
            bgcolor: 'bg-white',
            username: '',
            border: 'border-b',
        }
    },
    mounted() {
        this.setType(this.type);
        this.login = this.$store.state.login;
        this.username = this.$store.state.username;
    },
    methods: {
        click() {
            if(this.isNormal()) {
                this.$router.back();
            }
            else {
                this.openSidebar();
            }
        },
        openSidebar() {
            this.$emit('opensidebar');
        },
        setType(type) {
            let is_normal = type.toLowerCase() == 'normal';

            this.icon = require(is_normal ? "@/assets/icons/papsi_back_dark.svg" : "@/assets/icons/papsi_menu.svg");
            this.bgcolor = is_normal ? 'bg-white' : 'bg-primary';
            this.border = is_normal ? 'border-b' : 'border-none';
        },
        isNormal() {
            return this.type.toLowerCase() == 'normal';
        },
        goProfile() {
            this.$router.push('/profile');
        }
    },
    watch: {
        type(type) {
            this.setType(type);
        }
    }
}
</script>

<style scoped>
.papsi-back-icon {
    margin-top: 13px;
}
.papsi-snowman-icon {
    margin-top: 17px;
}
.papsi-avatar-mt {
    margin-top: 12px;
}
</style>