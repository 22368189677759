<template>
    <ModalControl @closed="closed"
        v-bind:show="display" 
        v-bind:title="title">
        <div>
            <div v-text="message" class="text-sm leading-normal pb-3"></div>
            <div class="flex text-white text-center text-sm font-bold">
                <div v-on:click="ok" v-text="lang('ok')" class="w-1/2 bg-primary py-3 mr-2 rounded uppercase"></div>
                <div v-on:click="cancel" v-text="lang('cancel')" class="w-1/2 bg-error py-3 rounded uppercase"></div>
            </div>
        </div>
    </ModalControl>
</template>

<script>
import ModalControl from './../controls/ModalControl.vue';
import mixin from './../../mixin.js';

export default {
    name: 'v-prompt',
    mixins: [
        mixin,
    ],
    components: {
        ModalControl,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            display: false,
        }
    },
    mounted() {
        this.display = this.show;
    },
    methods: {
        ok() {
            this.display = false;
            this.$emit('ok');
        },
        cancel() {
            this.display = false;
            this.$emit('cancel');
        },
        closed() {
            this.display = false;
            this.$emit('closed');
        }
    },
    watch: {
        show(n) {
            this.display = n;
        }
    }
}
</script>