<template>
    <div class="w-full h-screen bg-primary leading-normal">
        <div class="max-w-limit mx-auto">
            <div class="text-center pt-6 text-white text-3xl font-bold">
                <img class="papsi-main-logo" v-bind:src="image" />
            </div>
            <div class="text-center text-md text-white p-6 pt-0">
                <p v-text="lang('our.team.is.currently.doing.some.maintenance.work.in.our.systems.to.serve.you.better')"></p>
                <br /><br /><b v-text="lang('we.ll.be.right.back')"></b></div>
            <div class="px-4">
                <ButtonControl @clicked="reload" theme="warning">
                    {{ lang('reload') }}
                </ButtonControl>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../controls/ButtonControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
    },
    data() {
        return {
            image: '',
        }
    },
    mounted() {
        let title = this.lang('maintenance.mode');

        this.image = this.getMediaPath('/logo/papsi-mobile.jpg');
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });
    },
    methods: {
        reload() {
            window.location.reload();
        }
    },
}
</script>

<style scoped>
.papsi-main-logo {
    max-width: 200px;
}
</style>