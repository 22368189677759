<template>
    <ModalControl v-bind:show="display" v-bind:title="title" v-bind:theme="curr_theme">
        <div v-text="message" class="text-sm pb-3 leading-normal"></div>
        <div>
            <ButtonControl @clicked="closed" v-bind:theme="theme">
                {{ lang('ok') }}
            </ButtonControl>
        </div>
    </ModalControl>
</template>

<script>
import ButtonControl from './ButtonControl.vue';
import ModalControl from './ModalControl.vue';
import mixin from './../../mixin.js';

export default {
    name: 'v-message-box',
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ModalControl,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Error',
        },
        message: {
            type: String,
            default: '',
        },
        theme: {
            type: String,
            default: 'error',
        },
    },
    data() {
        return {
            display: false,
            curr_theme: 'error',
        }
    },
    mounted() {
        this.display = this.show;
        this.curr_theme = this.theme;
    },
    methods: {
        closed() {
            this.display = false;
        },
    },
    watch: {
        show(n) {
            this.display = n;

            if(!n) {
                this.$emit('closed');
            }
        },
        theme(curr) {
            this.curr_theme = curr;
        }
    }
}
</script>