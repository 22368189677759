<template>
    <div class="w-full bg-white border-t">
        <div class="flex text-xs font-bold h-navbar">
            <div v-on:click="navigate('/dashboard')" v-bind:class="{ 'bg-grey-lighter' : ($route.name == 'dashboard-page') }" class="flex-1 text-center h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_home.svg" /></div>
                <div v-text="lang('dashboard')"></div>
            </div>
            <div v-on:click="navigate('/profile')" v-bind:class="{ 'bg-grey-lighter' : ($route.name == 'profile-page') }" class="flex-1 text-center h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_avatar.svg" /></div>
                <div v-text="lang('profile')"></div>
            </div>
            <div v-on:click="navigate('/settings')" v-bind:class="{ 'bg-grey-lighter' : isSettingPage() }" class="flex-1 text-center h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_settings.svg" /></div>
                <div v-text="lang('settings')"></div>
            </div>
            <div v-on:click="navigate('/help')" v-bind:class="{ 'bg-grey-lighter' : ($route.name == 'need-help-page') }" class="flex-1 text-center h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_help.svg" /></div>
                <div v-text="lang('help')"></div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    data() {
        return {
            pages: [
                'settings-page',
                'password-setting-page',
                'username-setting-page',
                'mobile-setting-page',
                'email-setting-page',
                'deactivate-account-page',
                'auth-security-page',
                'settings-info-page',
                'update-tin-info-page',
                'login-history-page',
            ],
        }
    },
    methods: {
        isSettingPage() {
            return this.pages.indexOf(this.$route.name) !== -1;
        },
        navigate(path) {
            this.$router.push(path);
        },
    }
}
</script>
