<template>
    <div>
        <ContentWrapper class="mt-4">
            <div class="bg-white shadow rounded p-4 text-sm leading-normal" style="font-family: 'Courier New', Courier, monospace">
                <div class="pb-8">
                    We, at Philippine Army Finance Center Producers Integrated Cooperative
                    (PAFCPIC), are committed to provide you with quality and innovative
                    services while implementing safeguards to protect your privacy and keep
                    your personal information safe and secure in compliance with Republic Act
                    No. 10173, otherwise known as the “Data Privacy Act of 2012”.
                </div>
                <div class="font-bold text-md text-left">
                    COLLECTION OF PERSONAL INFORMATION
                </div>
                <div class="pb-8">
                    In the course of our transactions with you, PAFCPIC may collect your
                    personal information, including sensitive personal information, as defined
                    under the DPA. Your personal information may be collected through various
                    points of contact such as our Main Office, PAFCPIC Satellite Offices
                    (PSOs), PAFCPIC Offsite Service Engagement (POSE) Program, or through
                    other correspondence or similar means.
                </div>
                <div class="font-bold text-md text-left">
                    USE AND DISCLOSURE OF PERSONAL INFORMATION
                </div>
                <div class="pb-8">
                    PAFCPIC collects, uses, and discloses your personal information primarily
                    in managing your membership in the cooperative in relation to providing
                    quality and innovative financial and integrated products and services.
                    Your personal information will also be used to comply with regulatory
                    requirements and for other related legitimate purposes.
                </div>
                <div class="font-bold text-md text-left">
                    ACCESSING AND UPDATING YOUR PERSONAL INFORMATION
                </div>
                <div class="pb-8">
                    You may access and update personal information previously collected from
                    you by proceeding to any of our PAFCPIC Satellite Offices (PSOs) and
                    submitting a request to authorized employees. This information will be
                    available for your access during business hours and after a reasonable
                    period of time for us to prepare the same.
                </div>
                <div class="font-bold text-md text-left">
                    PERSONAL INFORMATION RETENTION AND DISPOSAL
                </div>
                    <div class="pb-8">
                    The personal information of data subjects shall be retained by PAFCPIC
                    only for as long as it is necessary to fulfill the purpose/s for which it
                    was obtained and in accordance with the prescribed retention period
                    depending on the nature of the personal information collected and subject
                    to the specific retention policy of the department or section storing such
                    information. Thereafter, the personal information shall be disposed of or
                    de-identified in a secure manner sufficient to make the information
                    non-personally identifiable in order to prevent further processing,
                    unauthorized access, or unlawful disclosure to any other party.
                </div>
                <div class="font-bold text-md text-left">
                    DATA PROTECTION
                </div>
                <div class="pb-8">
                    PAFCPIC implements reasonable and appropriate organizational, physical,
                    and technical security measures for the protection of personal information
                    which we collected. The security measures aim to maintain the
                    availability, integrity, and confidentiality of personal information and
                    are intended for the protection of personal information against any
                    accidental or unlawful destruction, alteration, and disclosure, as well as
                    against any other unlawful processing.
                    <br /><br />
                    In compliance to the Data Privacy Act, PAFCPIC has appointed a Data
                    Protection Officer (DPO) to manage and safeguard the processing of your
                    personal information. A Data Privacy Section under the direct supervision
                    of the DPO was also established for the same purpose. PAFCPIC commits
                    itself to ensuring that your personal information is secured and protected
                    under the standards provided for by law and in line with the best
                    practices of the industry.
                </div>
                <div class="font-bold text-md text-left">
                    CONFIDENTIALITY
                </div>
                <div class="pb-8">
                    Our employees shall process and hold personal information under strict
                    confidentiality. They are required to sign non- disclosure agreements and
                    are trained on the company’s privacy and security policies to ensure
                    confidentiality and security of personal information.
                </div>
                <div class="font-bold text-md text-left">
                    RIGHTS OF THE DATA SUBJECTS
                </div>
                <div class="pb-8">
                    <div class="pb-3">You are entitled to the following rights with respect to your personal information:</div>
                    <div>1. To be informed</div>
                    <div>2. To object</div>
                    <div>3. To access</div>
                    <div>4. To rectification</div>
                    <div>5. To erasure or blocking</div>
                    <div>6. To damages</div>
                    <div>7. To file a complaint</div>
                    <div>8. To data portability</div>
                </div>
                <div class="font-bold text-md text-left">
                    AMENDMENTS AND UPDATES TO PRIVACY POLICY
                </div>
                <div class="pb-8">
                    PAFCPIC reserves the right to regularly update and amend its Privacy
                    Policy, as needed, to comply with legal and regulatory requirements, to
                    reflect changes in the cooperative’s processes and procedures, to align
                    with industry practice, to adopt new technologies and methodologies, or
                    for other legitimate purposes. Any significant updates or amendments to
                    the Privacy Policy shall be duly communicated and disseminated to all
                    concerned parties.
                </div>
                <div class="font-bold text-md text-left">CONTACT INFORMATION</div>
                <div class="py-2">
                    If you have further questions or concerns, you may contact our Data Protection Officer through the following details:
                <div class="my-4">
                    <b>Contact Number:</b> (02) 8844-9010 loc. 310
                </div>
                <div>
                    <b>Email Address:</b> dpo.pafcpic@gmail.com
                </div>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapper,
    },
    mounted() {
        let title = this.lang('privacy.policy');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });
    }
}
</script>