<template>
    <div v-on:click="click" class="py-2 bg-grey-light rounded flex mb-1">
        <div class="w-icon h-icon ml-2">
            <img v-bind:src="getIcon()" class="block" />
        </div>
        <div class="flex-grow text-grey-darkest pl-1 truncate flex justify-content items-center">
            <span><slot></slot></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-menu',
    props: {
        href: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            required: true,
        },
    },
    methods: {
        click() {
            if(this.href != null) {
                this.$router.push(this.href);
            }
            
            this.$emit('clicked');
        },
        getIcon() {
            return require('./../../assets/' + this.icon);
        },
    }
}
</script>
