import { createApp } from 'vue'
import App from './App.vue'
import cookie from 'vue-cookies'
import router from './router'
import store from './store'
import "@/assets/styles/main.css";

createApp(App).use(store).use(router).use(cookie, {
    secure: true,
}).mount('#app')
