<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-6 rounded shadow mb-4">
            <div v-text="message" class="text-sm text-grey-dark mb-4"></div>
            <div>
                <ButtonControl v-on:click="exit">
                    {{ lang('exit') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
    },
    data() {
        return {
            message: '',
        }
    },
    mounted() {
        let title = 'Registration';

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: false,
        });

        if(!this.$cookies.isKey('registration_message'))
        {
            this.exit();
        }
        else {
            this.message = this.$cookies.get('registration_message');
        }
    },
    methods: {
        exit() {
            this.$cookies.remove('registration_message');
            this.$router.push('/');
        }
    }
}
</script>
