<template>
    <div class="h-screen w-full flex flex-col font-bold">
        <div class="bg-primary flex-1 flex flex-col" v-on:click="$router.push('/signup')">
            <div class="flex-grow"></div>
            <div class="text-center text-white flex h-full items-center justify-center" style="font-size: 60px;">REGULAR</div>
        </div>
        <div class="bg-secondary flex-1 flex flex-col" v-on:click="$router.push('/signup/express')">
            <div class="flex-grow"></div>
            <div class="text-center text-primary flex h-full items-center justify-center" style="font-size: 60px;">EXPRESS</div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$emit('hidenavbar');
    }
}
</script>