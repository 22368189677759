<template>
    <div v-bind:class="{ 'mb-4' : !nobottom }" class="max-w-limit m-auto px-4">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'v-content-wrapper',
    props: {
        nobottom: {
            type: Boolean,
        }
    },
}
</script>