import { createStore } from 'vuex'

export default createStore({
  state: {
    down: false,
    login: false,
    ip: null,
    branches: [],
    user_agent: {
      browser: {
        name: null,
        version: null,
      },
      device: {
        model: null,
        type: null,
        vendor: null,
      },
      engine: {
        name: null,
        version: null,
      },
      os: {
        name: null,
        version: null,
      },
      cpu: null,
    },
    uuid: null,
    hash: null,
    email: null,
    mobile: null,
    account_no: null,
    serial_no: null,
    first_name: null,
    last_name: null,
    middle_name: null,
    gender: null,
    date_of_birth: null,
    regular: false,
    memdate: null,
    memtype: null,
    tin: null,
    key: null,
    qr: null,
    lang: "en",
    config: [],
    username: null,
    password: null,
    status: null,
    registered: null,
    registration: {
      last_name: '',
      first_name: '',
      account_no: '',
      serial_no: '',
      username: '',
      email: '',
      mobile: '',
      password1: '',
      password2: '',
      agree: false,
    },
    election: {
      ballot: null,

      voting_start: null,
      voting_started: false,
      voting_end: null,
      voting_ended: false,
      reg_start: null,
      reg_started: false,
      reg_end: null,
      reg_ended: false,
      
      registered: false,
      voted: false,
      
      signed: false,
      rated: false,
      mev: false,
      can_vote: false,
      started: false,
      done: false,
    },
    candidates: {
      bod: [],
      elecom_of: [],
      elecom_ep: [],
      elecom_ci: [],
      audit: [],
    },
    selected: {
      bod: [],
      elecom_of: [],
      elecom_ep: [],
      elecom_ci: [],
      audit: [],
    },
    forgot: {
      uuid: null,
      hash: null,
      name: null,
      username: null,
      email: null,
      mobile: null,
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
