<template>
    <div>
        <PromptControl @ok="logout"
            @cancel="cancel"
            @closed="closed"
            v-bind:show="prompt.show"
            v-bind:theme="prompt.theme"
            v-bind:title="prompt.title"
            v-bind:message="prompt.message" />
        <div v-show="display">
            <div v-on:click="close" class="w-full h-screen fixed z-sidebar-screen papsi-modal-screen"></div>
            <div class="h-screen max-h-screen w-sidebar bg-primary fixed z-sidebar papsi-sidebar-notch">
                <div class="w-full h-screen flex flex-col">
                    <div v-bind:style="[ getLogo() ]" class="h-navbar w-full bg-primary bg-contain bg-no-repeat"></div>
                    <div class="flex-grow overflow-auto bg-white">
                        <div class="p-3">
                            <MenuControl v-for="item in modules" 
                                v-bind:href="item.href"
                                v-bind:icon="item.icon"
                                v-bind:key="item.id"
                                @clicked="close">
                                {{ lang(item.label) }}
                            </MenuControl>
                        </div>
                        <div class="p-3 border-t border-b">
                            <div v-on:click="openPrompt" class="bg-error rounded flex">
                                <div class="p-2 papsi-button-fader">
                                    <div class="w-icon h-icon">
                                        <img src="@/assets/icons/papsi_logout.svg" class="block" />
                                    </div>
                                </div>
                                <div class="font-bold text-sm text-white uppercase flex justify-content items-center pl-2">
                                    <span v-text="lang('log.out')"></span>
                                </div>
                            </div>
                        </div>
                        <div v-text="version" class="p-3 text-xs text-grey"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuControl from './../controls/MenuControl.vue';
import PromptControl from './../controls/PromptControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    name: 'v-sidebar',
    mixins: [
        mixin,
    ],
    components: {
        MenuControl,
        PromptControl,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            display: false,
            version: null,
            modules: [
                {
                    id: 'mod_company',
                    icon: 'icons/papsi_company.svg',
                    href: '/our-company',
                    label: 'our.company',
                },
                {
                    id: 'mod_branches',
                    icon: 'icons/papsi_branches.svg',
                    href: '/satellite-offices',
                    label: 'satellite.offices',
                },
            ],
            prompt: {
                show: false,
                theme: 'success',
                title: '',
                message: '',
            },
        }
    },
    mounted() {
        this.display = this.show;
        this.version = this.lang('version') + ' ' + process.env.VUE_APP_VERSION;
    },
    methods: {
        openPrompt() {
            this.prompt.title = this.lang('log.out');
            this.prompt.message = this.lang('are.you.sure.you.want.to.leave');
            this.display = false;
            this.prompt.show = true;
        },
        cancel() {
            this.display = true;
        },
        closed() {
            this.prompt.show = false;
        },
        close() {
            this.display = false;
            this.$emit('closed');
        },
        getLogo() {
            return "background-image: url('" + this.getMediaPath('/logo/papsi-navbar-sm.png') + "')";
        },
        logout() {
            let that = this,
                state = this.$store.state;

            that.$emit('showloader');
            User.logout(state.uuid).then(function() {
                that.$cookies.remove('uuid');
                that.$cookies.remove('hash');
                that.resetUserData();
                that.$emit('logout');
                that.$emit('hideloader');
                that.$router.push('/');
            }).catch(function() {
                that.$emit('terminate', 403);
            });
        },
    },
    watch: {
        show(n) {
            this.display = n;
        }
    }
}
</script>

<style scoped>
.papsi-modal-screen {
    background-color: rgba(0, 0, 0, 0.85);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    top: 0px;
    left: 0px;
}
.papsi-sidebar-notch {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    top: 0px;
    left: 0px;
}
.papsi-button-fader {
    background-color: rgba(255, 255, 255, 0.10);
}
</style>