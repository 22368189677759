<template>
  <div class="w-full h-screen fixed flex bg-primary flex-col overflow-hidden z-loading-screen papsi-loading-screen">
    <div class="flex-grow">
      <div v-bind:style="getLogo()" class="w-logo h-logo mx-auto bg-contain bg-no-repeat papsi-pulse papsi-logo"></div>
    </div>
  </div>
</template>

<script>
import mixin from './../../mixin.js';

export default {
  mixins: [
    mixin,
  ],
  methods: {
    getLogo() {
      return "background-image: url('" + this.getMediaPath('/logo/pafcpic.png') + "')";
    },
  },
}
</script>

<style scoped>
.papsi-loading-screen {
  top: 0px;
  left: 0px;
}
.papsi-logo {
    margin-top: calc((100vh - 180px) / 2);
}
.papsi-pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}
</style>
