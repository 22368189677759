<template>
    <div v-show="display" v-bind:class="[ bgcolor ]" class="mb-3 rounded w-full flex flex-row text-white text-xs">
        <div class="px-2 papsi-icon-bg">
            <img v-bind:src="getIcon()" class="my-3 w-icon" />
        </div>
        <div class="flex-grow p-3">
            <slot></slot>
        </div>
        <div v-on:click="close" 
            v-show="dismiss"
            class="text-xl font-bold p-3">
            &times;
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-alert',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            required: false,
            default: 'success',
        },
        dismiss: {
            type: Boolean,
            default: false,
        },
        autoclose: {
            type: Boolean,
            default: false,
        },
        delay: {
            type: Number,
            default: 4000,
        },
    },
    data() {
        return {
            display: false,
            bgcolor: 'bg-success',
        }
    },
    mounted() {
        this.display = this.show;
        this.bgcolor = 'bg-' + this.theme;
    },
    methods: {
        getIcon() {
            return require('./../../assets/icons/papsi_' + this.theme + '.svg');
        },
        close() {
            this.display = false;
            this.$emit('closes');
        },
    },
    watch: {
        theme(theme) {
            this.bgcolor = 'bg-' + theme;
        },
        display(shown) {
            if(shown && this.autoclose) {
                let that = this,
                    t = setTimeout(() => { 
                        that.display = false;
                        that.$emit('closes');
                        clearTimeout(t);
                    }, this.delay);
            }
        },
        show(val) {
            this.display = val;
        },
    },
}
</script>

<style scoped>
.papsi-icon-bg {
    background-color: rgba(255, 255, 255, 0.20);
}
</style>