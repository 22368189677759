<template>
    <div>
        <ModalControl @closed="closed" v-bind:show="display" v-bind:title="lang('one.time.password')" theme="success">
            <div class="p-2">
                <div v-text="desc" class="mb-3 text-sm leading-normal"></div>
                <AlertControl v-bind:show="has_error" 
                    @closes="alertClose"
                    :delay="5000"
                    theme="error"
                    autoclose
                    dismiss>
                    {{ message }}
                </AlertControl>
                <div class="mb-3">
                    <TextControl @typing="typing"
                        v-bind:value="value"
                        :maxlength="6"
                        name="otp" 
                        type="text" />
                </div>
                <div v-show="show_resend">
                    <div v-show="!freezed" class="mb-3">
                        <span v-text="lang('resend')" v-on:click="resend" class="text-sm text-blue underline"></span>
                    </div>
                    <div v-show="freezed" class="mb-3 text-sm text-grey-dark">
                        {{ lang('you.can.resend.again.after') }} <span v-text="seconds"></span> {{ lang('seconds') }}. 
                    </div>
                </div>
                <div class="flex">
                    <div class="flex-1 mr-2">
                        <ButtonControl v-on:click="validate" v-bind:disabled="disabled" theme="success">
                            {{ lang('validate') }}
                        </ButtonControl>
                    </div>
                    <div class="flex-1">
                        <ButtonControl v-on:click="cancel" theme="error">
                            {{ lang('cancel') }}
                        </ButtonControl>
                    </div>
                </div>
            </div>
        </ModalControl>
    </div>
</template>

<script>
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ModalControl from './ModalControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import OTP from './../../services/OTP.js';
import mixin from './../../mixin.js';

export default {
    name: 'v-otp-modal',
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ModalControl,
        TextControl,
    },
    props: {
        type: {
            type: Number,
            default: 1,
        },
        show: {
            type: Boolean,
            default: false,
        },
        otp: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        mobile: {
            type: String,
            default: null,
        },
        desc: {
            type: String,
            default: 'Enter the one time password we have sent to your email address or mobile number to proceed.',
        },
        pin: {
            type: String,
            default: null,
        },
        keeploader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            seconds: 180,
            freezed: false,
            has_error: false,
            display: false,
            disabled: true,
            show_resend: false,
            message: '',
            value: '',
        }
    },
    mounted() {
        this.message = this.lang('something.went.wrong');
        this.seconds = parseInt(process.env.VUE_APP_OTP_DELAY);
    },
    methods: {
        validate() {
            let that = this;

            this.$emit('showloader');
            OTP.validate(this.type, this.value, this.email, this.mobile).then(function() {
                that.display = false;
                that.reset();
                that.$emit('matches');

                if(!that.keeploader) {
                    that.$emit('hideloader');
                }
            }).catch(function(error) {
                that.value = '';
                that.message = error.response.data.message;
                that.has_error = true;
                that.$emit('terminate', error.response.status);

                if(!that.keeploader && error.response.status != 403) {
                    that.$emit('hideloader');
                }
            });
        },
        reset() {
            this.has_error = false;
            this.show_resend = false;
            this.value = '';
        },
        typing(obj) {
            this.value = obj.value;
            this.disabled = this.value.length != 6;
        },
        cancel() {
            this.display = false;
            this.$emit('cancel');
        },
        closed() {
            this.$emit('closed');
        },
        alertClose() {
            this.has_error = false;
        },
        resend() {
            this.$emit('showloader');
            let that = this;

            OTP.request(this.type, this.name, this.email, this.mobile, this.pin).then(function(response) {
                that.$emit('resend', response.data.data.otp);
                that.freezed = true;
                that.$emit('hideloader');
                that.startCountdown();
            }).catch(function(error) {
                that.$emit('terminate', error.response.status);
            });
        },
        startCountdown() {
            let that = this,
                t = setInterval(function() {
                that.seconds--;
                if(that.seconds == 0) {
                    that.seconds = parseInt(process.env.VUE_APP_OTP_DELAY);
                    that.freezed = false;
                    clearInterval(t);
                }
            }, 1000);
        },
        showResendLink() {
            let that = this,
                n = 0,
                t = setInterval(function() {
                    n++;

                    if(n == parseInt(process.env.VUE_APP_OTP_DELAY)) {
                        that.show_resend = true;
                        clearInterval(t);
                    }
                }, 1000);
        },
    },
    watch: {
        show(n) {
            this.display = n;

            if(this.display) {
                this.showResendLink();
            }
            else {
                this.show_resend = false;
            }
        },
    },
}
</script>