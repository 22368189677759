<template>
    <div v-show="display">
        <div v-show="display" class="w-full h-screen fixed z-modal-screen papsi-modal-screen"></div>
        <div class="w-full h-screen fixed z-modal-box papsi-modal-box">
            <div class="px-6 w-full h-screen flex justify-center items-center">
                <div class="bg-white w-full shadow papsi-modal-w ">
                    <div v-bind:class="[ bgcolor ]" class="p-3 flex text-white">
                        <div v-text="title" class="flex-grow font-bold text-sm uppercase"></div>
                        <div v-on:click="close" 
                            v-show="dismiss" 
                            class="font-bold text-lg pl-3">&times;</div>
                    </div>
                    <div v-bind:class="{ 'p-3': !nopadd }" class="bg-white overflow-auto papsi-modal-max-h">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-modal',
    props: {
        title: {
            type: String,
        },
        dismiss: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: 'success',
        },
        show: {
            type: Boolean,
            default: false,
        },
        nopadd: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            display: true,
            bgcolor: 'bg-success',
        }
    },
    mounted() {
        this.bgcolor = 'bg-' + this.theme;
        this.display = this.show;
    },
    methods: {
        close() {
            if(this.dismiss) {
                this.display = false;
                this.$emit('closed');
            }
        },
    },
    watch: {
        show(n) {
            this.display = n;

            if(!n) {
                this.$emit('closed');
            }
        },
        theme(curr) {
            this.bgcolor = 'bg-' + curr;
        },
    }
}
</script>

<style scoped>
.papsi-modal-screen {
    background-color: rgba(0, 0, 0, 0.85);
    left: 0px;
    top: 0px;
}
.papsi-modal-box {
    left: 0px;
    top: 0px;
}
.papsi-modal-w {
    max-width: 480px;
}
.papsi-modal-max-h {
    max-height: calc(100vh - 200px);
}
</style>