<template>
    <div>
        <ContentWrapper class="mt-4">
            <div class="bg-white shadow rounded p-4 leading-normal papsi-terms-font">
                <div class="text-sm leading-5 pb-6">
                    PAPSI Mobile is licensed to You (End-User) by Philippine Army Finance Center Producers
                    Integrated Cooperative (PAFCPIC), located and registered at PAFCPIC Bldg., Bayani Road,
                    Taguig, Metro Manila 1630, Philippines (&quot;<p class="font-bold inline">Licensor</p>&quot;), for use only under the terms of this License
                    Agreement.
                </div>
            <div class="text-sm leading-5 pb-6">
                By downloading the Licensed Application from Apple&#39;s software distribution platform (&quot;App
                Store&quot;) and Google&#39;s software distribution platform (&quot;Play Store&quot;), and any update thereto (as
                permitted by this License Agreement), You indicate that You agree to be bound by all of the
                terms and conditions of this License Agreement, and that You accept this License Agreement.
                App Store and Play Store are referred to in this License Agreement as &quot;<p class="font-bold inline">Services</p>.&quot;
            </div>
            <div class="text-sm leading-5 pb-6">
                The parties of this License Agreement acknowledge that the Services are not a Party to this
                License Agreement and are not bound by any provisions or obligations with regard to the
                Licensed Application, such as warranty, liability, maintenance and support thereof. Philippine
                Army Finance Center Producers Integrated Cooperative (PAFCPIC), not the Services, is solely
                responsible for the Licensed Application and the content thereof.
            </div>
            <div class="text-sm leading-5 pb-6">
                This License Agreement may not provide for usage rules for the Licensed Application that are in
                conflict with the latest <p class="font-bold inline underline">Apple Media Services Terms and Conditions</p> <p class="font-bold inline underline">and Google Play Terms of
                Service (&quot;Usage Rules&quot;).</p> Philippine Army Finance Center Producers Integrated Cooperative
                (PAFCPIC) acknowledges that it had the opportunity to review the Usage Rules and this License
                Agreement is not conflicting with them.
            </div>
            <div class="text-sm leading-5 pb-6">
                PAPSI Mobile when purchased or downloaded through the Services, is licensed to You for use only
                under the terms of this License Agreement. The Licensor reserves all rights not expressly
                granted to You. PAPSI Mobile is to be used on devices that operate with Apple&#39;s operating systems
                (&quot;¡OS&quot; and &quot;Mac OS&quot;) or Google&#39;s operating system (&quot;Android&quot;).
            </div>

            <div class="text-sm">
                <p class="font-bold underline pb-2">TABLE OF CONTENTS</p>
                <p class="pl-4">1. THE APPLICATION</p>
                <p class="pl-4">2. SCOPE AND LICENSE</p>
                <p class="pl-4">3. TECHNICAL REQUIREMENTS</p>
                <p class="pl-4">4. MAINTENANCE AND SUPPORT</p>
                <p class="pl-4">5. USE OF DATA</p>
                <p class="pl-4">6. USER-GENERATED CONTRIBUTIONS</p>
                <p class="pl-4">7. CONTRIBUTION LICENSE</p>
                <p class="pl-4">8. LIABILITY</p>
                <p class="pl-4">9. WARRANTY</p>
                <p class="pl-4">10. PRODUCT CLAIMS</p>
                <p class="pl-4">11. LEGAL COMPLIANCE</p>
                <p class="pl-4">12. CONTACT INFORMATION</p>
                <p class="pl-4">13. TERMINATION</p>
                <p class="pl-4">14. THIRD-PARTY TERMS OF AGREEMENT &amp; BENEFICIARY</p>
                <p class="pl-4">15. INTELLECTUAL PROPERTY RIGHTS</p>
                <p class="pl-4">16. APPLICABLE LAW</p>
                <p class="pl-4">17. MISCELLANEOUS</p>
            </diV>

            <p class="font-bold text-base mt-6">1. APPLICATION</p>
            <div class="text-sm leading-5 pb-5"> PAPSI Mobile <p class="font-bold inline">(&quot;Licensed Application&quot;&#39;)</p> is a piece of software created to facilitate online banking
                transactions via mobile application - and customized for iOS and Android n
                “Devices”). It is used to apply for membership, apply for loans, and conduct other member-related
                transactions.
            </div>

            <p class="font-bold text-base">2. SCOPE OF LICENSE</p>
            <div class="text-sm leading-5 pb-5">
                2.1 You are given a non-transferable, non-exclusive, non-sublicensable license to install and
                use the Licensed Application on any Devices that You (End-User) own or control and as
                permitted by the Usage Rules, with the exception that such Licensed Application may be
                accessed and used by other accounts associated with You (End-User, The Purchaser) via
                Family Sharing or volume purchasing.
            </div>
            <div class="text-sm leading-5 pb-5">
                2.2 This license will also govern any updates of the Licensed Application provided by Licensor
                that replace, repair, and/or supplement the first Licensed Application, unless a separate license
                Is provided for such update, in which case the terms or that new license will govern.
            </div>
            <div class="text-sm leading-5 pb-5"> 
                2.3 You may not share or make the Licensed Application available to third parties unless to the
                `degree allowed by the Usage Rules, and with Philippine Army Finance Center Producers
                Integrated Cooperative (PAFCPIC)&#39;s prior written consent), sell, rent, lend, lease or otherwise`
            </div>
            <div class="text-sm leading-5 pb-5">
                    2.4 You may not reverse engineer, translate, disassemble, integrate, decompile, remove
                modify, combine, create derivative works or updates of, adapt, or attempt to derive the source
                Center Producers Integrated Cooperative (PAFCPIC)&#39;s prior written consent).
            </div>
            <div class="text-sm leading-5 pb-5">
                2.5 You may not copy (excluding when expressly authorized by this license and the Usage
                Rules) or alter the Licensed Application or portions thereof. You may create and store copies
                only on devices that You own or control for backup keeping under terms of this license, the Usage Rules,
                and any other terms and conditions that apply to the device or software used. You
                may not remove any intellectual property notices. You acknowledge that no unauthorized third
                parties may gain access to these copies at any time. If you sell your Devices to a third party, you
                must remove the Licensed Application from the Devices before doing so.
            </div>
            <div class="text-sm leading-5 pb-5">
                2.6 Violations of the obligations mentioned above, as well as the attempt of such infringement,
                may be subject to prosecution and damages.
            </div>
            <div class="text-sm leading-5 pb-5">
                2.7 Licensor reserves the right to modify the terms and conditions of licensing.
            </div>
            <div class="text-sm leading-5 pb-5">
                2.8 Nothing in this license should be interpreted to restrict third-party terms. When using the
                Licensed Application, You must ensure that You comply with applicable third-party terms and conditions
            </div>
            <p class="font-bold text-base"> 3. TECHNICAL REQUIREMENTS</p>
            <div class="text-sm leading-5 pb-5">
                3.1 Licensor attempts to keep the Licensed Application updated so that it complies with
                modified/new versions of the firmware and new hardware. You are not granted rights to claim
                such an update.
            </div>
            <div class="text-sm leading-5 pb-5">
                3.2 You acknowledge that it is Your responsibility to confirm and determine that the app end-
                user device on which You intend to use the Licensed Application satisfies the technical
                specifications mentioned above.
            </div>
            <div class="text-sm leading-5 pb-5">
                3.3 Licensor reserves the right to modify the technical specifications as it sees appropriate at
                any time.
            </div>
            <p class="font-bold text-base">4. MAINTENANCE AND SUPPORT</p>
            <div class="text-sm leading-5 pb-5">
                4.1 The Licensor is solely responsible for providing any maintenance and support services for
                this Licensed Application. You can reach the Licensor at the email address listed in the App
                Store or Play Store Overview for this Licensed Application.
            </div>
            <div class="text-sm leading-5 pb-5">
                4.2 Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) and the End-
                User acknowledge that the Services have no obligation whatsoever to furnish any maintenance
                and support services with respect to the Licensed Application.
            </div>
            <p class="font-bold text-base">5. USE OF DATA</p>
            <div class="text-sm leading-5 pb-5">
                You acknowledge that Licensor will be able to access and adjust Your downloaded Licensed
                Application content and your personal information, and that Licensor&#39;s use of such material and
                information is subject to Your legal agreements with Licensor and Licensor&#39;s privacy policy,
                which can be found at the bottom of the Licensed Application.
            </div>
            <div class="text-sm leading-5 pb-5">
                You acknowledge that the Licensor may periodically collect and use technical data and related
                information about your device, system, and application software, and peripherals, offer product
                support, facilitate the software updates, and for purposes of providing other services to you (if
                any) related to the Licensed Application. Licensor may also use this information to improve its
                products or to provide services or technologies to you, as long as it is in a form that does not
                personally identify you.
            </div>
            <p class="font-bold text-base">6. USER-GENERATED CONTRIBUTIONS</p>
            <div class="text-sm leading-5 pb-5">
                The Licensed Application may invite you to chat, contribute to, or participate in blogs, message
                boards, online forums, and other functionality, and may provide you with the opportunity to
                create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and
                materials to us or in the Licensed Application, including but not limited to text, writings, video,
                audio, photographs, graphics, comments, suggestions, or personal information or other material
                (collectively, &quot;Contributions&#39;). Contributions may be viewable by other users of the Licensed
                Application and through third-party websites or applications. As such, any Contributions you
                transmit may be treated as non-confidential and non-proprietary. When you create or make
            </div>
            <p>available any Contributions, you thereby represent and warrant that:</p>
            <div class="text-sm leading-5 pb-5">
                1. The creation, distribution, transmission, public display, or performance, and the accessing,
                downloading, or copying of your Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent, trademark, trade secret, or moral
                rights of any third party.
            </div>
            <div class="text-sm leading-5 pb-5">
                2. You are the creator and owner of or have the necessary licenses, rights, consents,
                releases, and permissions to use and to authorize us, the Licensed Application, and other
                users of the Licensed Application to use your Contributions in any manner contemplated by
                the Licensed Application and this License Agreement. The
            </div>
            <div class="text-sm leading-5 pb-5">
                3. You have the written consent, release, and/or permission of each and every identifiable
                individual person in your Contributions to use the name or likeness or each and every such
                identifiable individual person to enable inclusion and use of your Contributions in any manner
                contemplated by the Licensed Application and this License Agreement.
            </div>
            <div class="text-sm leading-5 pb-5">
                4. Your Contributions are not false, inaccurate, or misleading.
            </div>
            <div class="text-sm leading-5 pb-5">
                5. Your Contributions are not pyramid schemes, chain letters, spam, mass mailings, or other forms
                of solicitation.
            </div>
            <div class="text-sm leading-5 pb-5">
                6. Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous,
                slanderous, or otherwise objectionable (as determined by us).
            </div>
            <div class="text-sm leading-5 pb-5">
                7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
            </div>
            <div class="text-sm leading-5 pb-5">
                8. Your Contributions are not used to harass or threaten (in the legal sense of those terms)
                any other person and to promote violence against a specific person or class of people.
            </div>
            <div class="text-sm leading-5 pb-5">
                9. Your Contributions do not violate any applicable law, regulation, or rule.
            </div>
            <div class="text-sm leading-5 pb-5">
                10. Your Contributions do not violate the privacy or publicity rights of any third party.
            </div>
            <div class="text-sm leading-5 pb-5">
                11. Your Contributions do not violate any applicable law concerning child pornography, or
                otherwise intended to protect the health or well-being of minors.
            </div>
            <div class="text-sm leading-5 pb-5">
                12. Your Contributions do not include any offensive comments that are connected to race,
                national origin, gender, sexual preference, or physical handicap.
            </div>
            <div class="text-sm leading-5 pb-5">
                13. Your Contributions do not otherwise violate, or link to material that violates, any provision
                of this License Agreement, or any applicable law or regulation.
            </div>
            <div class="text-sm leading-5 pb-5">
                Any use of the Licensed Application in violation of the foregoing violates this License Agreement
                and may result in, among other things, termination or suspension of your rights to use the
                Licensed Application.
            </div>
            <p class="font-bold text-base">7. CONTRIBUTION LICENSE</p>
            <div class="text-sm leading-5 pb-5">
                By posting your Contributions to any part of the Licensed Application or making Contributions accessible
                to the Licensed Application by linking your account from the Licensed Application to any of your social
                networking accounts, you automatically grant, and you represent and warrant that you have the right to
                grant, to us an unrestricted, unlimited, irrevocable, perpetual, non- exclusive, transferable, royalty-free,
                fully-paid, worldwide right, and license to host, use copy, reproduce, disclose, sell, resell, publish, broad
                cast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or in
                part), and distribute such Contributions (including, without limitation, your image and voice) for any
                purpose, commercial advertising, or otherwise, and to prepare derivative works of, or incorporate in other works, such as Contributions, and grant and authorize sublicenses of the foregoing. The use and
                distribution may occur in any media formats and through any media channels.
            </div>
            <div class="text-sm leading-5 pb-5">
                This license will apply to any form, media, or technology now known or hereafter developed, and
                includes our use of your name, company name, and franchise name, as applicable, and any of the
                trademarks, service marks, trade names, logos, and personal and commercial images you provide. You
                waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been
                asserted in your Contributions.
            </div>
            <div class="text-sm leading-5 pb-5">
                We do not assert any ownership over your Contributions. You retain full ownership of all of your
                Contributions and any intellectual property rights or other proprietary rights associated with your
                Contributions. We are not liable for any statements or representations in your Contributions provided
                by you in any area in the Licensed Application. You are solely responsible for your Contributions to the
                Licensed Application and you expressly agree to exonerate us from any and all responsibility and to
                refrain from any legal action against us regarding your Contributions.
            </div>
             <div class="text-sm leading-5 pb-5">
                We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any
                Contributions; (2) to recategorize any Contributions to place them in more appropriate locations in the
                Licensed Application; and (3) to prescreen or delete any Contributions at any time and for any reason,
                without notice. We have no obligation to monitor your Contributions.
            </div>
            <p class="font-bold text-base">8. LIABILITY</p>
            <div class="text-sm leading-5 pb-5">
                8.1 Licensor takes no accountability or responsibility for any damages caused due to a breach of duties
                according to Section 2 of this License Agreement. To avoid data loss, you are required to make use of
                backup functions of the Licensed Application to the extent allowed by applicable third-party terms and
                conditions of use. You are aware that in case of alterations or manipulations of the Licensed Application,
                You will not have access to the Licensed Application.
            </div>
            <p class="font-bold text-base">9. WARRANTY</p>
            <div class="text-sm leading-5 pb-5">
                9.1 Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any
                other malware at the time of Your download. Licensor warrants that the licensed Application works as
                described in the user documentation.
            </div>
            <div class="text-sm leading-5 pb-5">
                9.2 No warrant is provided for the Licensed Application that is not execute on the device, that has been
                unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate
                hardware or software, used with inappropriate hardware or software, used with inappropriate
                accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of
                Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC)&#39;s sphere of influence that
                affect the executability of the Licensed Application.
            </div>
            <div class="text-sm leading-5 pb-5">
                9.3 You are required to inspect the Licensed Application immediately after installing it and notify
                Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) about issues discovered
                without delay by email provided in Product Claims. The defect report will be taken into consideration
                and further investigated if it has been emailed within a period of thirty (30) days after discover.
            </div>
            <div class="text-sm leading-5 pb-5">
                9.4 If we confirm that the Licensed Application is defective, Philippine Army Finance Center Producers
                Integrated Cooperative (PAFCPIC) reserves a choice to remedy the situation either by means of solving
                the defect or substitute delivery.
            </div>
            <div class="text-sm leading-5 pb-5">
                9.5 In the event of any failure of the Licensed Application to conform to any applicable warranty. You
                may notify the Services Store Operator, and Your Licensed Application purchase price will be refunded
                to You, To the maximum extent permitted by applicable law, the Services Store Operator will have no
                other warranty obligation whatsoever with respect to the Licensed Application, and any other losses.
                Claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any
                warranty.
            </div>
            <div class="text-sm leading-5 pb-5">
                9.6 If the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation
                amounting to twelve (12) months after the Licensed Application was made available to the user. The
                statutory periods of limitation given by law apply for users who are consumers.
            </div>
            <p class="font-bold text-base">10. PRODUCT CLAIMS</p>
            <div class="text-sm leading-5 pb-5">
                Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) and the End-User
                acknowledge that Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC), and not
                the Services, is responsible for addressing any claims of the End-User or any third party relating to the
                Licensed Application or the End-User&#39;s possession and/or use of that Licensed Application, including, but
                not limited to:
            </div>

            <div class="text-sm mb-5">
                <p>(i) product liability claims;</p>
                <p>(ii) any Claim that the Licensed Application falls to conform any applicable legal or regulatory
                requirement; and</p>
                <p>(iii) claims arising under consumer protection, privacy, or similar legislation, including in
                connection with Your Licensed Application’s use of the Healthkit and Homekit. Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC)</p> is
            </div>
            
            <p class="font-bold text-base">11. LEGAL COMPLIANCE</p>
            <div class="text-sm leading-5 pb-5">
                You represent and warrant that You are not located in a country that is subject to a US Government
                embargo, or that has been designated by the US Government as a &quot;terrorist supporting&quot; country; and
                that You are not listed on any US Government list of prohibited or restricted parties.
            </div>
            <p class="font-bold text-base">12. CONTACT INFORMATION</p>
            <div class="text-sm leading-5 pb-5">
                For general inquiries, complaints, questions or claims concerning the Licensed Application, please
                contact:
            </div>
            <p>Philippine Army Finance Center Producers Integrated Cooperative</p>
            <p>Marketing &amp; Communications Section</p>
            <p>PAFCPIC Bldg., Bayani Road</p>
            <p>Taguig, Metro Manila 1630 Philippines</p>
            <p>customer@pafcpic365.com</p>

            <p class="font-bold text-base">13. TERMINATION</p>
            <div class="text-sm leading-5 pb-5">
                The license is valid until terminated by Philippine Army Finance Center Producers Integrated
                Cooperative (PAFCPIC) or by You. Your rights under this license will terminate automatically and without
                notice from Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) if You fail to
                adhere to any term(s) of this license. Upon License termination, You shall stop all use of the Licensed
                Application, and destroy all copies, full or partial, of the Licensed Application.
            </div>
            <p class="font-bold text-base">14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</p>
            <div class="text-sm leading-5 pb-5">
                Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) represents and warrants
                that Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) will comply with
                applicable third-party terms of agreement when using Licensed Application.
                In Accordance with Section 9 of the &quot;Instructions for Minimum Terms of Developer&#39;s End-User License
                Agreement,&quot; both Apple and Google and their subsidiaries shall be third-party beneficiaries of this End
                User License Agreement and - upon Your acceptance of the terms and conditions of this License
                Agreement, both Apple and Google will have the right (and will be deemed to have accepted the right)
                to enforce this End User License Agreement against You as a third-party beneficiary thereof.
            </div>
            <p class="font-bold text-base">15. INTELLECTUAL PROPERTY RIGHTS</p>
            <div class="text-sm leading-5 pb-5">
                Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC) and the End-User
                acknowledge that, in the event of any third-party claim that the Licensed Application or the End- User&#39;s
                possession and use of that Licensed Application infringes on the third party&#39;s intellectual property rights,
                Philippine Army Finance Center Producers Integrated Cooperative (PAFCPIC), and not the Services, will
                be solely responsible for the investigation, defense, settlement, and discharge or any such intellectual
                property infringement claims.
            </div>
            <p class="font-bold text-base">16. APPLICABLE LAW</p>
            <div class="text-sm leading-5 pb-5">
                This License Agreement is governed by the laws of the Philippines excluding its conflicts of law rules.
            </div>
            <p class="font-bold text-base">17. MISCELLANEOUS</p>
            <div class="text-sm leading-5 pb-5">
                17.1 If any of the terms of this agreement should be or become invalid, the validity of the remaining
                provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will
                achieve the primary purpose.
            </div>
            <div class="text-sm leading-5">
                17.2 Collateral agreements, changes and amendments are only valid if laid down in writing. The
                preceding clause can only be waived in writing.
            </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapper,
    },
    mounted() {
        let title = this.lang('end.user.license.agreement');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });
    }
}
</script>

<style scoped>
.papsi-terms-font {
    font-family: 'Courier New', Courier, monospace;
}
</style>