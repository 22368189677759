import assets from './asset.manifest.js';

export default {
    data() {
        return {
            tested: [],
            loaded_asset: [],
            error_asset: [],
            complete: null,
            delay: 1000,
        }
    },
    methods: {
        preloadStart(complete) {
            this.complete = complete;
            this.start();
        },
        start() {
            let that = this;

            assets.forEach(function(asset) {
                that.tested.push(asset);

                let image = new Image();
                    image.src = process.env.VUE_APP_MEDIA_URL + 'images' + asset;
                    image.onload = function() {
                        that.loaded_asset.push(asset);
                        that.loadTest();
                    };
                    image.onerror = function() {
                        that.error_asset.push(asset);
                        that.errorTest();
                    };
            });
        },
        loadTest() {
            let that = this;

            if(this.loaded_asset.length == assets.length) {
                let t = setTimeout(function() {
                    that.complete();
                    clearTimeout(t);
                }, that.delay);
            }
        },
        errorTest() {
            let that = this;
            
            if(this.tested.length == assets.length) {
                let t = setTimeout(function() {
                    that.complete();
                    clearTimeout(t);
                }, that.delay);
            }
        }
    }
}